import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
    return (
        <div>
            <div className='hero-container'>
                <video src="/videos/video-3.mp4" autoPlay loop muted />
                <h1>Dacia and Sage</h1>
                <p>will be right back. For now click below</p>
                <div className='hero-btns'>
                    <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                    >
                    Shop
                    </Button>
                </div>
            </div>
            
        </div>
    )
}

export default HeroSection
